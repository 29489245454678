import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "equipmentSelect", "quantityInput", "selectedFrame", "fields", "selectedAsset", "insertBtn" ]
  static values = {
    equipmentUrl: String
  }

  connect() {
    // Dynamic nodes for insertion
    $(this.insertBtnTarget).
      data("association-insertion-node", (link) => {
        const type = this.selectedAssetTarget.dataset.deviceType
        return $(`#${type}-list`)
      })

    // Update template before inserting
    $(document).on("cocoon:before-insert", this.element, (e, item, originalEvent) => {
      // If the equipment that is being added is already present in the list, prevent a new field from being added and instead add to the quantity.
      const selectedOption = this.equipmentSelectTarget.selectedOptions[0]
      const addedEquipmentId = selectedOption.value
      const existingEquipmentCard = document.querySelector(`.nested-fields .card-body[data-equipment-id="${addedEquipmentId}"]`)

      if (existingEquipmentCard) {
        e.preventDefault()

        // Unhide nested field in case it was hidden
        const parent = existingEquipmentCard.closest(".nested-fields")
        const wasHidden = parent.style.display == "none"
        parent.style.display = "inherit"

        // Remove the destroy flag
        const destroyInput = parent.querySelector("[id$='_destroy']")
        destroyInput.value = false

        // Add to quantity
        const quantityToAdd = this.quantityInputTarget.value
        const quantityInput = existingEquipmentCard.querySelector(".quantity")

        if (wasHidden) {
          quantityInput.value = parseInt(quantityToAdd)
        } else {
          quantityInput.value = parseInt(quantityInput.value) + parseInt(quantityToAdd)
        }
      }

      // Unhide list if it was hidden
      const typeList = document.querySelector(`#${selectedOption.dataset.type}-list`)
      if (typeList) {
        typeList.parentNode.style.display = "inherit"
      }

      this.updateTemplate(item[0])
      this.clear()
    })

    $(document).on("cocoon:before-remove", this.element, (e, item, originalEvent) => {
      const typeList = item.closest("[id$='-list']")[0]

      // If the list has no other nodes than the one we are currently removing, hide the type.
      const otherVisibleNodes = Array.from(typeList.querySelectorAll(".nested-fields")).filter((el) => getComputedStyle(el).display !== "none")
      if (otherVisibleNodes.length <= 1) {
        typeList.parentNode.style.display = "none"
      }
    })
  }

  select(e) {
    const selectedValue = e.currentTarget.value
    const updatedUrl = this.equipmentUrlValue + `?equipment_id=${selectedValue}`

    // Hide the fields and remove all except the placeholder.
    this.fieldsTarget.classList.add("hidden")
    this.selectedFrameTarget.querySelectorAll("*:not(.hidden):not(.hidden *)").forEach((element) => { element.remove() })

    // When the frame has loaded, show the fields only if we have an equipment selected.
    this.selectedFrameTarget.addEventListener("turbo:frame-load", () => {
      if (selectedValue) {
        this.fieldsTarget.classList.remove("hidden")
      } else {
        this.fieldsTarget.classList.add("hidden")
      }
    }, { once: true })

    // Show the placeholder, update the frame src and update the cocoon insertion node
    if (selectedValue) {
      this.selectedFrameTarget.querySelector("turbo-frame > .hidden").classList.remove("hidden")
      this.selectedFrameTarget.src = updatedUrl
    }
  }

  cancel() {
    this.clear()
  }

  /** FUNCTIONS **/

  clear() {
    this.equipmentSelectTarget.value = ""
    this.quantityInputTarget.value = this.quantityInputTarget.min

    const event = new Event("change", { bubbles: true })
    this.equipmentSelectTarget.dispatchEvent(event)
  }

  updateTemplate(template) {
    const selectedOption = this.equipmentSelectTarget.selectedOptions[0]

    // Fetch device info
    const deviceInfo = {}
    deviceInfo["name"] = selectedOption.dataset.name
    deviceInfo["description"] = this.truncateString(selectedOption.dataset.description, 40)
    deviceInfo["quantity"] = this.quantityInputTarget.value

    template.querySelector(".card-body").dataset.equipmentId = selectedOption.value
    template.querySelector(".equipment-id-input").value = selectedOption.value
    template.querySelector(".name").textContent = deviceInfo["name"]
    template.querySelector(".description").textContent = deviceInfo["description"]
    template.querySelector(".quantity").value = deviceInfo["quantity"]
  }

  truncateString(str, maxLength) {
    if (str)
      return str.length > maxLength ? str.slice(0, maxLength) + "..." : str
  }
}
